export default function Navigation () {
	toggleNavMenu()
}

function toggleNavMenu() {
	const mobileMenuOpen = document.getElementById('nav-menu-icon-open');
	const mobileMenuClose = document.getElementById('nav-menu-icon-close');
	const navLinks = document.getElementsByClassName('navbar-links')[0];
	const navbar = document.getElementsByClassName('navbar')[0];

	mobileMenuOpen.addEventListener('click', function(){
		this.classList.add('hidden');
		mobileMenuClose.classList.remove('hidden');
		navLinks.classList.add('mobile-toggle');
		navbar.classList.add('full-white');
	});

	mobileMenuClose.addEventListener('click', function(){
		this.classList.add('hidden');
		mobileMenuOpen.classList.remove('hidden');
		navLinks.classList.remove('mobile-toggle');
		navbar.classList.remove('full-white');
	});

	window.onscroll = function() {onScroll()};

	function onScroll() {
		if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
			navbar.classList.add('navbar-scroll');
		} else {
			navbar.classList.remove('navbar-scroll');
		} 
	};

}